import HTTP from '@/api/HTTP';
const auth = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    errorMsg: '',
    successMsg: '',
  },
  getters: {
    auth: (state) => state.items,
  },
  actions: {
    async login({ commit }, payload) {
      commit('setSuccessMsg', null);
      commit('setErrorMsg', null);
      try {
        commit('setIsLoading', true);
        const response = await HTTP.post('/auth/login', payload);
        commit('setIsLoading', false);
        if (response.status == 200) {
          commit('setSuccessMsg', 'Login success');
          commit('setItem', response.data.data);
        }
        return response;
      } catch (error) {
        commit('setIsLoading', false);
        if (error?.status == 403) {
          commit('setErrorMsg', "You don't have access");
          return error;
        }
        const response = error.response;
        if (response?.status == 422) {
          if (response.data?.data?.errors) {
            response.data.data.errors
              .reverse()
              .map((e) => commit('setErrorMsg', e?.msg));
          } else {
            commit('setErrorMsg', response?.data?.message);
          }
        } else {
          commit('setErrorMsg', response?.data?.message);
        }
        return error;
      }
    },

    async register({ commit }, payload) {
      commit('setSuccessMsg', null);
      commit('setErrorMsg', null);
      try {
        commit('setIsLoading', true);
        const response = await HTTP.post('/auth/register', payload);
        commit('setIsLoading', false);
        if (response.status == 201) {
          commit(
            'setSuccessMsg',
            'Your account is created. Please check your email to verify account !',
          );
        }
        return response;
      } catch (error) {
        commit('setIsLoading', false);
        const response = error.response;
        if (response?.status == 422) {
          if (response.data?.data?.errors) {
            response.data.data.errors
              .reverse()
              .map((e) => commit('setErrorMsg', e?.msg));
          } else {
            commit('setErrorMsg', response.data.message);
          }
        } else {
          commit('setErrorMsg', response.data.message);
        }
      }
    },

    async emailVerification({ commit }, payload) {
      commit('setSuccessMsg', null);
      commit('setErrorMsg', null);
      try {
        const response = await HTTP.put('/auth/email-verification', payload);
        if (response.status == 200) {
          commit(
            'setSuccessMsg',
            'Your account has been successfully verified',
          );
        }
        return response;
      } catch (error) {
        commit('setIsError', true);
        const response = error.response;
        if (response?.status == 422) {
          if (response.data?.data?.errors) {
            response.data.data.errors
              .reverse()
              .map((e) => commit('setErrorMsg', e?.msg));
          } else {
            commit('setErrorMsg', response.data.message);
          }
        } else {
          commit('setErrorMsg', response.data.message);
        }
      }
    },
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setItem(state, item) {
      state.item = item;
    },
    setTotalRows(state, totalRows) {
      state.totalRows = totalRows;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsError(state, isError) {
      state.isError = isError;
    },
    setErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg;
    },
    setSuccessMsg(state, successMsg) {
      state.successMsg = successMsg;
    },
  },
};

export default auth;
