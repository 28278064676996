import HTTP from '@/api/HTTP';
const transaction = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    isLoading: false,
    isError: false,
    errorMsg: '',
    successMsg: '',
    totalRows: 0,
  },
  getters: {
    transaction: (state) => state.items,
  },
  actions: {
    async fetchTransaction({ commit }, props) {
      commit('setErrorMsg', null);
      commit('setSuccessMsg', null);
      try {
        commit('setIsLoading', true);
        const transaction = await HTTP.get('/transaction', { params: props });
        commit('setItems', transaction.data.data.rows);
        commit('setTotalRows', transaction.data.data.count);
        commit('setIsLoading', false);
        return transaction;
      } catch (error) {
        const response = error.response;
        if (response?.status == 422) {
          commit('setErrorMsg', response?.data?.message);
        }
        if (response?.status == 500) {
          commit('setErrorMsg', response?.data?.data?.message);
        }
        commit('setIsLoading', false);
        return error;
      }
    },
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setTotalRows(state, totalRows) {
      state.totalRows = totalRows;
    },
    setItem(state, item) {
      state.item = item;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsError(state, isError) {
      state.isError = isError;
    },
    setErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg;
    },
    setSuccessMsg(state, successMsg) {
      state.successMsg = successMsg;
    },
  },
};

export default transaction;
