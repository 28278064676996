import HTTP from '@/api/HTTP';
const cart = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    errorMsg: '',
    successMsg: '',
    countCart: 0,
  },
  getters: {
    cart: (state) => state.items,
  },
  actions: {
    async fetchCart({ commit }) {
      commit('setSuccessMsg', null);
      commit('setErrorMsg', null);
      try {
        const carts = await HTTP.get('/cart');
        commit('setItems', carts.data.data);
        return carts;
      } catch (error) {
        const response = error.response;
        if (response?.status == 500) {
          commit('setErrorMsg', response?.data?.message);
        }
        return error;
      }
    },
    async addCart({ commit }, payload) {
      commit('setSuccessMsg', null);
      commit('setErrorMsg', null);
      try {
        commit('setIsLoading', true);
        const cart = await HTTP.post('/cart', payload);
        if (cart?.status == 200) {
          commit('setSuccessMsg', 'Added to cart');
        }
        commit('setIsLoading', false);
        return cart;
      } catch (error) {
        const response = error.response;
        if (response?.status == 422) {
          if (response.data.errors) {
            response.data.errors
              .reverse()
              .map((e) => commit('setErrorMsg', e?.msg));
          }
        }
        if (response?.status == 500) {
          commit('setErrorMsg', response?.data?.message);
        }
        commit('setIsLoading', false);
        return error;
      }
    },

    async getCountCart({ commit }) {
      try {
        const response = await HTTP.get('/get-total-cart');
        commit('setCountCart', response.data.data);
        return response;
      } catch (error) {
        commit('setCountCart', 0);
        return error;
      }
    },

    async getCountCartLogged({ commit }) {
      try {
        const response = await HTTP.get('/cart/get-total-cart');
        commit('setCountCart', response.data.data);
        return response;
      } catch (error) {
        commit('setCountCart', 0);
        return error;
      }
    },

    async removeItemCart({ commit }, payload) {
      commit('setSuccessMsg', null);
      commit('setErrorMsg', null);
      try {
        const response = await HTTP.delete(
          '/cart/delete-item-product/' + payload.id,
        );
        if (response?.status == 200) {
          commit('setSuccessMsg', 'Item deleted');
        }
        return response;
      } catch (error) {
        const response = error.response;
        if (response?.status == 422) {
          if (response.data.errors) {
            response.data.errors
              .reverse()
              .map((e) => commit('setErrorMsg', e?.msg));
          }
        }
        if (response?.status == 500) {
          commit('setErrorMsg', response?.data?.message);
        }
        return error;
      }
    },
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setItem(state, item) {
      state.item = item;
    },
    setTotalRows(state, totalRows) {
      state.totalRows = totalRows;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsError(state, isError) {
      state.isError = isError;
    },
    setErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg;
    },
    setSuccessMsg(state, successMsg) {
      state.successMsg = successMsg;
    },
    setCountCart(state, countCart) {
      state.countCart = countCart;
    },
  },
};

export default cart;
