import Vue from 'vue';
import VueRouter from 'vue-router';

const Home = () => import('../views/Home.vue');
const MainTemplate = () => import('../views/MainTemplate.vue');
const Shop = () => import('../views/Shop.vue');
const PageNotFound = () => import('../views/PageNotFound.vue');
const Contact = () => import('../views/Contact.vue');
const Login = () => import('../views/Login.vue');
const Register = () => import('../views/Register.vue');
const Profile = () => import('../views/Profile.vue');
const EmailVeification = () => import('../views/Auth/EmailVerification.vue');

const Product = [require('./Product/product').ProductDetail];
const Cart = [require('./Cart/Cart').Cart];
// const Auth = [require('./auth').EmailVeification];
const Payment = [require('./Payment/payment').Payment];
const Transaction = [require('./Transaction/transaction').Transaction];

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'main-template',
    component: MainTemplate,
    children: [
      ...Product,
      ...Cart,
      // ...Auth,
      ...Payment,
      ...Transaction,
      {
        path: '/',
        name: 'home',
        component: Home,
      },
    ],
  },
  {
    path: '/shop',
    name: 'shop',
    component: Shop,
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/register',
    name: 'regiter',
    component: Register,
  },
  {
    path: '/send-email-verification/:id',
    name: 'email-verification',
    component: EmailVeification,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
  },
  {
    path: '*',
    name: 'page-not-found',
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
});

export default router;
