import HTTP from '@/api/HTTP';
const order = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorMsg: '',
    successMsg: '',
  },
  getters: {
    order: (state) => state.items,
  },
  actions: {
    async getOrderDetail({ commit }, payload) {
      commit('setSuccessMsg', null);
      commit('setErrorMsg', null);
      try {
        const order = await HTTP.get('/order/' + payload.id);
        if (!order?.data?.data) {
          commit('setErrorMsg', 'No data found');
        }
        commit('setItem', order?.data?.data);
        return order;
      } catch (error) {
        const response = error.response;

        if (response?.status == 500) {
          commit('setErrorMsg', response?.data?.message);
        }
        return error;
      }
    },
    async checkStatusOrder({ commit }, payload) {
      commit('setSuccessMsg', null);
      commit('setErrorMsg', null);
      commit('setIsSuccess', false);
      try {
        commit('setIsLoading', true);
        const order = await HTTP.get('/order/check-status/' + payload.id);
        commit('setIsSuccess', true);
        commit('setIsLoading', false);
        return order;
      } catch (error) {
        const response = error.response;
        if (response?.status == 500) {
          commit('setErrorMsg', response?.data?.message);
        }
        if (response?.status == 422) {
          if (response?.data?.errors.length) {
            response?.data?.errors.map((e) => commit('setErrorMsg', e?.msg));
          }
        }
        commit('setIsLoading', false);
        return error;
      }
    },
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setItem(state, item) {
      state.item = item;
    },
    setTotalRows(state, totalRows) {
      state.totalRows = totalRows;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsError(state, isError) {
      state.isError = isError;
    },
    setIsSuccess(state, isSuccess) {
      state.isSuccess = isSuccess;
    },
    setErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg;
    },
    setSuccessMsg(state, successMsg) {
      state.successMsg = successMsg;
    },
  },
};

export default order;
