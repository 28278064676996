import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import product from './Product/product';
import profile from './Profile/profile';
import cart from './Cart/cart';
import rajaongkir from './Rajaongkir/rajaongkir';
import checkout from './Checkout/checkout';
import order from './Order/order';
import transaction from './Transaction/transaction';

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    auth,
    product,
    profile,
    cart,
    rajaongkir,
    checkout,
    order,
    transaction,
  },
  strict: 'development',
});
