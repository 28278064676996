import axios from 'axios';
let token = localStorage.getItem('CWS_session_token');
let latest_refresh_token = localStorage.getItem('CWS_refresh_token');
let clientKey =
  'SGFueWEgTWFudXNpYSBCaWFzYSBZYW5nIEJlcnVzYWhhIE1lcmFpaCBNaW1waQ==';
const baseURL = process.env.VUE_APP_BASE_API_URL;

const HTTP = axios.create({
  baseURL,
});

//Request interceptor for API calls
HTTP.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: 'application/json',
      Authorization: `${clientKey}`,
      'access-token': token,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);
let refreshing_token = null;
HTTP.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      try {
        originalRequest._retry = true;
        const { refresh_token, access_token } = refreshing_token
          ? refreshing_token
          : await refreshAccessToken();
        refreshing_token = null;
        HTTP.defaults.headers.common['access-token'] = access_token;
        localStorage.removeItem('CWS_session_token');
        localStorage.removeItem('CWS_refresh_token');
        localStorage.setItem(
          'CWS_refresh_token',
          refresh_token ? refresh_token : latest_refresh_token,
        );
        localStorage.setItem('CWS_session_token', access_token);
        token = access_token;
        latest_refresh_token = refresh_token;
        return HTTP(originalRequest);
      } catch (err) {
        return Promise.reject(err);
      }
    } else if (error.response.status === 403) {
      let err = error.response;
      return Promise.reject(err);
    } else {
      return Promise.reject(error);
    }
  },
);

async function refreshAccessToken() {
  if (
    !latest_refresh_token ||
    latest_refresh_token == '' ||
    latest_refresh_token == 'undefined'
  ) {
    localStorage.removeItem('CWS_session_token');
    localStorage.removeItem('CWS_refresh_token');
    token = null;
    latest_refresh_token = null;
    window.location.href = '/login';
  } else {
    let payload = {
      refresh_token: latest_refresh_token,
      grant_type: 'CWS_refresh_token',
    };
    try {
      let response = await HTTP.post('/auth/refresh-token', payload);
      let body = response.data.data;
      return {
        access_token: body.token,
        refresh_token: body.refresh_token,
      };
    } catch (error) {
      localStorage.removeItem('CWS_session_token');
      localStorage.removeItem('CWS_refresh_token');
      token = null;
      latest_refresh_token = null;
      window.location.href = '/login';
    }
  }
}

export default HTTP;
