import HTTP from '@/api/HTTP';
const rajaongkir = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    isLoading: false,
    isError: false,
    errorMsg: '',
    successMsg: '',
    cities: [],
    subdistricts: [],
    cost: [],
  },
  getters: {
    rajaongkir: (state) => state.items,
  },
  actions: {
    async fetchProvince({ commit }) {
      commit('setErrorMsg', null);
      try {
        const provinces = await HTTP.get('/shipping/province');
        commit('setItems', provinces.data.data);
        return provinces;
      } catch (error) {
        const response = error.response;
        if (response?.status == 500 || response?.status == 422) {
          commit('setErrorMsg', response?.data?.message);
        }
        return error;
      }
    },

    async fetchCity({ commit }, payload) {
      commit('setErrorMsg', null);
      try {
        const city = await HTTP.get('/shipping/city/' + +payload.province_id);
        commit('setCities', city.data.data);
        return city;
      } catch (error) {
        const response = error.response;
        if (response?.status == 500 || response?.status == 422) {
          commit('setErrorMsg', response?.data?.message);
        }
        return error;
      }
    },

    async fetchSubdistrict({ commit }, payload) {
      commit('setErrorMsg', null);
      try {
        const subdistrict = await HTTP.get(
          '/shipping/subdistrict/' + +payload.city_id,
        );
        commit('setSubdistricts', subdistrict.data.data);
        return subdistrict;
      } catch (error) {
        const response = error.response;
        if (response?.status == 500 || response?.status == 422) {
          commit('setErrorMsg', response?.data?.message);
        }
        return error;
      }
    },

    async getCostShipping({ commit }, payload) {
      commit('setErrorMsg', null);
      try {
        commit('setIsLoading', true);
        const cost = await HTTP.post('/shipping/cost', payload);
        commit('setCost', cost?.data?.data);
        commit('setIsLoading', false);
        return cost;
      } catch (error) {
        const response = error.response;
        if (response?.status == 500 || response?.status == 422) {
          commit('setErrorMsg', response?.data?.message);
        }
        commit('setIsLoading', false);
        return error;
      }
    },
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setItem(state, item) {
      state.item = item;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsError(state, isError) {
      state.isError = isError;
    },
    setErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg;
    },
    setSuccessMsg(state, successMsg) {
      state.successMsg = successMsg;
    },
    setCities(state, cities) {
      state.cities = cities;
    },
    setSubdistricts(state, subdistricts) {
      state.subdistricts = subdistricts;
    },
    setCost(state, cost) {
      state.cost = cost;
    },
  },
};

export default rajaongkir;
