import HTTP from '@/api/HTTP';
const profile = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    errorMsg: '',
    successMsg: '',
  },
  getters: {
    profile: (state) => state.items,
  },
  actions: {
    async getProfileUser({ commit }) {
      commit('setErrorMsg', null);
      commit('setSuccessMsg', null);
      try {
        const profile = await HTTP.get('/profile');
        commit('setItem', profile?.data?.data);
        return profile;
      } catch (error) {
        const response = error.response;
        commit('setErrorMsg', response?.data?.message);
        return error;
      }
    },
    async setAddress({ commit }, payload) {
      commit('setErrorMsg', null);
      commit('setSuccessMsg', null);
      try {
        const profile = await HTTP.post('/profile', payload);
        commit('setSuccessMsg', 'Successfully set your address');
        return profile;
      } catch (error) {
        commit('setErrorMsg', 'Ops. Something went wrong. Please try again !');
        return error;
      }
    },
    async getUserAddress({ commit }) {
      commit('setErrorMsg', null);
      commit('setSuccessMsg', null);
      try {
        const address = await HTTP.get('/profile/get-user-address');
        commit('setItem', address?.data?.data);
        return address;
      } catch (error) {
        commit('setErrorMsg', 'Ops. Something went wrong. Please try again !');
        return error;
      }
    },
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setItem(state, item) {
      state.item = item;
    },
    setTotalRows(state, totalRows) {
      state.totalRows = totalRows;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsError(state, isError) {
      state.isError = isError;
    },
    setErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg;
    },
    setSuccessMsg(state, successMsg) {
      state.successMsg = successMsg;
    },
  },
};

export default profile;
