import HTTP from '@/api/HTTP';
const product = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    errorMsg: '',
    successMsg: '',
  },
  getters: {
    product: (state) => state.items,
  },
  actions: {
    async fetchProduct({ commit }, props) {
      try {
        commit('setIsLoading', true);
        commit('setIsError', false);
        const products = await HTTP.get('/products', { params: props });
        commit('setTotalRows', products.data.data.count);
        commit('setItems', products.data.data.rows);
        commit('setIsLoading', false);
        return products;
      } catch (error) {
        commit('setIsLoading', false);
        commit('setIsError', true);
      }
    },

    async fetchDetailProduct({ commit }, payload) {
      commit('setIsError', false);
      try {
        commit('setIsLoading', true);
        const product = await HTTP.get('/products/detail/' + payload);
        commit('setItem', product.data.data);
        commit('setIsLoading', false);
        return product;
      } catch (error) {
        commit('setIsLoading', false);
        commit('setIsError', true);
      }
    },
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setItem(state, item) {
      state.item = item;
    },
    setTotalRows(state, totalRows) {
      state.totalRows = totalRows;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsError(state, isError) {
      state.isError = isError;
    },
    setErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg;
    },
    setSuccessMsg(state, successMsg) {
      state.successMsg = successMsg;
    },
  },
};

export default product;
